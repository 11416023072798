<template>
	<section class="blur-bckg">
		<aside class="widgets-popup">
			<div class="widgets-popup__header">
				<p>Interactive Widgets</p>
				<span class="close" @click="close"
					><v-icon>mdi-close</v-icon></span
				>
			</div>

			<div class="widgets-popup__title">
				<h2>
					<slot name="title"> {{ title }}</slot>
				</h2>
			</div>

			<component
				:is="currentWidget.component"
				:chapter="selectedChapter"
				:chapterId="chapterId"
				:pageId="pageId"
				:activePage="activePage"
				:editedBlock="editedBlock"
				:currentWidget="currentWidget"
				:message="message"
				:widgetMessageStatus="widgetMessageStatus"
				@getCourse="$emit('getCourse')"
				@saveBlock="$emit('saveBlock', $event)"
				@close="close"
				@setMessage="$emit('setMessage', $event)"
				@setWidgetMessageStatus="
					$emit('setWidgetMessageStatus', $event)
				"
			/>
		</aside>
	</section>
</template>
<script>
import WidgetPopupConnect from "./WidgetPopupConnect.vue";
import WidgetPopupMultipleQuestion from "./WidgetPopupMultipleQuestion.vue";
import WidgetPopupTrueFalse from "./WidgetPopupTrueFalse.vue";
import WidgetPopupComparison from "./WidgetPopupComparison.vue";
import WidgetPopupBulletList from "./WidgetPopupBulletList.vue";
import WidgetPopupTextBlock from "./WidgetPopupTextBlock.vue";
import WidgetPopupHeadline from "./WidgetPopupHeadline.vue";
import WidgetPopupParagraph from "./WidgetPopupParagraph.vue";
import WidgetPopupImage from "./WidgetPopupImage.vue";
import WidgetPopupVideo from "./WidgetPopupVideo.vue";

export default {
	name: "WidgetPopup",
	components: {
		WidgetPopupConnect,
		WidgetPopupTrueFalse,
		WidgetPopupMultipleQuestion,
		WidgetPopupComparison,
		WidgetPopupBulletList,
		WidgetPopupTextBlock,
		WidgetPopupHeadline,
		WidgetPopupParagraph,
		WidgetPopupImage,
		WidgetPopupVideo
	},
	props: {
		currentWidget: {
			type: Object,
			require: true
		},
		chapters: {
			type: Array,
			require: true
		},
		editedBlock: {
			type: Object,
			require: true
		},
		activePage: {
			type: Object,
			require: true
		},
		selectedChapter: {
			type: Object,
			require: true
		},
		message: {
			type: String,
			require: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		chapterId() {
			return this.selectedChapter?.id;
		},
		pageId() {
			return this.activePage?.id;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},

			title: "Connect"
		};
	},
	methods: {
		close() {
			this.$emit("closePopup", true);
		}
	}
};
</script>

<style></style>
