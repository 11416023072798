<template>
	<section class="widget-content">
		<article class="widget-headline">


				<medium-editor
					:options='options'
					v-model="text"
					label="Text block headline"
					auto-grow
					outlined
					rows="4"
					row-height="2"
					class="textinput"
				>
					<template #label>Paragraph #{{ index + 1 }}</template>
				</medium-editor>





			<v-row>
				<v-col cols="6" class="mt-10">
					<v-select
						v-model="style"
						:items="headlines"
						:item-text="'txt'"
						:item-value="'value'"
						label="Title style"
						dense
						outlined
					></v-select>
				</v-col>
			</v-row>
		</article>
		<article>
			<form-message v-if="message" :type="widgetMessageStatus">{{
				message
			}}</form-message>
		</article>

		<div class="widget-bullet-list__button">

<v-btn
	class="w-100 admin-primary-button primary-contrast-background"
	:loading="isLoading"
	@click="submit"
	>Done</v-btn
>
</div>

	</section>
</template>
<script>
import MediumEditor from 'vuejs-medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'
import 'highlight.js/styles/ocean.css'

import FormMessage from "@/components/FormMessage.vue";
import { mapGetters } from "vuex";

import TurndownService from 'turndown'
const turndownService = new TurndownService()
export default {
	name: "WidgetPopupHeadline",
	components: { FormMessage, MediumEditor },
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		content() {
			if (this.editedBlock === null) return;
			return JSON.parse(this.editedBlock.block.content);
		},
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},
	watch: {
		editedBlockId: function () {
			this.initGetData();
		}
	},

	data() {
		return {
			headlines: [
				{
					txt: "h1",
					value: 1
				},
				{
					txt: "h2",
					value: 2
				},
				{
					txt: "h3",
					value: 3
				},
				{
					txt: "h4",
					value: 4
				},
				{
					txt: "h5",
					value: 5
				},
				{
					txt: "h6",
					value: 6
				}
			],
			rules: {
				required: value => !!value || "Required."
			},
			style: 1,
			text: "",
			options: {
				placeholder: false,
				toolbar: {
				buttons: ["bold", "italic", "underline"]
				}
      		}
		};
	},

	methods: {
		initGetData() {
			if (this.editedBlock === null) return;
			this.style = this.editedBlock?.block.head_level;
			this.text = this.editedBlock?.block.header;
		},
		submit() {
			let header = turndownService.turndown(this.text)

			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: Number(this.$route.params.id),
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								header: header,
								head_level: this.style,
								small: this.editedBlock.block.small,
								css_class: this.editedBlock.block.css_class,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								header: header,
								head_level: this.style,
								small: null,
								css_class: null,
								sort_order: Number(
									this.activePage.blocks.length
								)
						  },
				blkType: this.currentWidget.blk_type
			};
			this.$emit("saveBlock", payload);
		}
	},
	mounted() {
		this.initGetData();
	}
};
</script>

<style lang="scss">
.insert-image-container {
	display: none !important;
}
.textinput {
	border: 1px solid #333;
	margin-bottom: 20px;
}

.medium-editor-container {
	padding: 15px 20px;

	p {
		margin-bottom: 0;
	}
}
</style>
