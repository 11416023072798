<template>

	<section class="widget-video widget-content">


		<v-card>
			<v-tabs
				ligth
				background-color="transparent"
				:slider-color="$store.getters.theme.mainAccentColor"
				slider-size="8"
				color="#000"
				v-model="imageType"
				class="brand-tabs-container"
			>

				<v-tab value="fileUpload" @click="clearImage()">File Upload</v-tab>
				<v-tab value="textUpload" @click="clearImage()">Video URL</v-tab>

			</v-tabs>
		</v-card>

<div v-if="imageType == 0">

		<div v-if="isEdited">
			<InputImageFile
				v-model="video"
				:id="'widget_video_uploader'"
				:name="'widget_video_uploader'"
				:accept="'video/*'"
			>
				<template #button>Upload Video</template>
			</InputImageFile>

			<v-btn
				class="admin-primary-button"
				v-if="editedBlock !== null"
				@click="setVideoEdited(false)"
				>Cancel</v-btn
			>
		</div>

		<div v-else class="d-flex flex-row align-center">
			<h4>{{ videoName }}</h4>

			<v-icon class="ml-4" @click="setVideoEdited(true)"
				>mdi-pencil-outline</v-icon
			>
		</div>

</div>

<div v-if="imageType == 1" class="mt-5">

		<v-textarea
			label="Video URL"
			auto-grow
			outlined
			rows="3"
			row-height="25"
			v-model="url"
		></v-textarea>

</div>

		<article>
			<form-message v-if="message" :type="widgetMessageStatus">{{
				message
			}}</form-message>
		</article>

		<div class="widget-bullet-list__button">

<v-btn
	class="w-100 admin-primary-button primary-contrast-background"
	:loading="isLoading"
	@click="submit"
	>Done</v-btn
>
</div>

	</section>
</template>
<script>
import InputImageFile from "@/components/InputImageFile.vue";
import FormMessage from "@/components/FormMessage.vue";
import { post } from "@/util/requests/post";
import { mapGetters } from "vuex";

export default {
	name: "WidgetPopupVideo",
	components: {
		InputImageFile,
		FormMessage
	},

	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["userId", "isLoading"]),
		videoName() {
			return this.video.name;
		},
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			url: "",
			imageType: 1,
			video: null,
			isEdited: true
		};
	},
	watch: {
		editedBlockId: function () {
			this.initGetData();
		}
	},

	methods: {
		initGetData() {
			if (this.editedBlock === null) return;
			this.url = this.editedBlock?.block.caption;
			this.video = this.editedBlock?.block.file;
			this.isEdited = false;
		},
		setVideoEdited(val) {
			this.isEdited = val;
		},
		async submit() {
			this.$emit("setMessage", {
				val: "",
				type: "widget"
			});
			this.$emit("setWidgetMessageStatus", "");

			if (this.video === null) {
				this.$emit("setMessage", {
					val: "Please upload image",
					type: "widget"
				});
				this.$emit("setWidgetMessageStatus", "error");
				return;
			}

			const response = await this.uploadVideo();
			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: Number(this.$route.params.id),
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								file_id: response.data.data.id,
								title: this.editedBlock.block.title,
								head_level: this.editedBlock.block.head_level,
								caption: this.url,
								css_class: this.editedBlock.block.css_class,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								file_id: response.data.data.id,
								title: "Video",
								head_level: 2,
								caption: this.url ? this.url : "",
								css_class: null,
								sort_order: Number(
									this.activePage.blocks.length
								)
						  },
				blkType: this.currentWidget.blk_type
			};
			this.$emit("saveBlock", payload);
		},
		async uploadVideo() {
			const response = await post("/file", {
				user_id: this.userId,
				storage: "video",
				name: this.video.name,
				header: this.video.name,
				ext: this.video.name,
				path: "",
				comment: "Video block"
			});
			return response;
		}
	},
	created() {
		this.initGetData();
	}
};
</script>
<style lang="scss" scoped>
.imagePreview {
	overflow: hidden;
    height: 50%;
    width: 100%;
    border: 1px solid #9e9e9e;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

	img {
		width: 50%;
		margin: 0 auto;
		display: block;
	}
}
</style>
