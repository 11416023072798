<template>
	<section class="widget-content">
		<article class="widget-connect">
			<p>Question text</p>


			<medium-editor
					:options='options'
					class="textinput"
					v-model="question"
				label="Type question"
				auto-grow
				outlined
				rows="10"
				row-height="2"
				>
				</medium-editor>

			<v-row class="mt-4">
				<v-col cols="5">
					<p>Column 1</p>
				</v-col>
				<v-col cols="2"> </v-col>
				<v-col cols="5">
					<p>Column 2</p>
				</v-col></v-row
			>
			<v-row
				class="widget-connect__row"
				v-for="(question, index) in sourcesNodes"
				:key="question.id"
				:id="question.id"
			>
				<v-col cols="5">
					<article>

						<medium-editor
					:options='options'
					class="textinput"
					v-model="question.content"
							label="Response text"
							auto-grow
							outlined
							rows="2"
							row-height="20"
				>
				</medium-editor>

						<InputImageFile
				v-model="question.content.img"
				:id="'widget_image_uploader'"
				:name="'widget_image_uploader'"
			>
				<template #button>Upload Image</template>
			</InputImageFile>

					</article>
				</v-col>
				<v-col cols="2" class=" px-0 widget-connect__row--iconwrapper"
					><span class="widget-connect__row--icon"
						><v-icon>mdi-chevron-right-box</v-icon></span
					></v-col
				>
				<v-col cols="5">
					<article>


						<medium-editor
					:options='options'
					class="textinput"
					v-model="targetNodes[index].content"
							label="Response text"
							auto-grow
							outlined
							rows="2"
							row-height="20"
				>
				</medium-editor>


						<InputImageFile
				v-model="targetNodes[index].content.img"
				:id="'widget_image_uploader'"
				:name="'widget_image_uploader'"
			>
				<template #button>Upload Image</template>
			</InputImageFile>

					</article>
				</v-col>
			</v-row>

			<v-btn
				@click="addPair"
				class="admin-primary-button secondary-contrast-background primary-outline mb-5"
				>Add Line</v-btn
			>
		</article>
		<article>
			<form-message v-if="message" :type="widgetMessageStatus">{{
				message
			}}</form-message>
		</article>



		<div class="widget-bullet-list__button">

<v-btn
	class="w-100 admin-primary-button primary-contrast-background"
	:loading="isLoading"
	@click="submit"
	>Done</v-btn
>
</div>

	</section>
</template>
<script>
import MediumEditor from 'vuejs-medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'
import 'highlight.js/styles/ocean.css'

import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import FormMessage from "@/components/FormMessage.vue";

import TurndownService from 'turndown'
const turndownService = new TurndownService()
export default {
	name: "WidgetPopupConnect",
	components: { FormMessage, MediumEditor },
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},
	watch: {
		editedBlockId: function () {
			this.initGetData();
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			question: "",
			sourcesNodes: [],
			targetNodes: [],
			options: {
				placeholder: false,
				toolbar: {
				buttons: ["bold", "italic", "underline"]
				}
      		}
		};
	},

	methods: {
		initGetData() {
			if (this.editedBlock === null) {
				this.addPair();
			} else {
				this.sourcesNodes = this.editedBlock.block.source_nodes;
				this.targetNodes = this.editedBlock.block.target_nodes;
				this.question = this.editedBlock.block.question;
			}
		},

		addPair() {
			const sourceId = uuidv4();
			const sourceNode = {
				id: sourceId,
				content: "",
				isNew: true,
				source: true
			};
			const targetNode = {
				id: uuidv4(),
				content: "",
				sourceId: sourceId,
				isNew: true,
				source: false
			};
			this.sourcesNodes.push(sourceNode);
			this.targetNodes.push(targetNode);
		},

		async submit() {
			this.$emit("setMessage", {
				val: "",
				type: "widget"
			});
			this.$emit("setWidgetMessageStatus", "");

			if (this.question === "") {
				this.$emit("setMessage", {
					val: "Please fill field",
					type: "widget"
				});
				this.$emit("setWidgetMessageStatus", "error");
				return;
			}

			let question = turndownService.turndown(this.question)

			this.sourcesNodes.forEach((i,v) => {
				this.sourcesNodes[v].content = turndownService.turndown(i.content)
			})

			this.targetNodes.forEach((i,v) => {
				this.targetNodes[v].content = turndownService.turndown(i.content)
			})

			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: this.editedBlock.block.course_id,
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								title: this.editedBlock.block.title,
								head_level: this.editedBlock.block.head_level,
								question: question,
								css_class: null,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								title: "Connect",
								head_level: 2,
								question: question,
								css_class: null,
								sort_order: Number(
									this.activePage.blocks.length
								)
						  },
				blkType: this.currentWidget.blk_type,
				elements: {
					sourcesNodes: this.sourcesNodes,
					targetNodes: this.targetNodes
				}
			};
			this.$emit("saveBlock", payload);
		}
	},
	mounted() {
		this.initGetData();
	}
};
</script>

<style lang="scss">
.insert-image-container {
	display: none !important;
}
.textinput {
	border: 1px solid #333;
	margin-bottom: 20px;
}

.medium-editor-container {
	padding: 15px 20px;

	p {
		margin-bottom: 0;
	}
}

.insert-image-container {
	display: none !important;
}
.textinput {
	border: 1px solid #333;
	margin-bottom: 20px;
}

.medium-editor-container {
	padding: 15px 20px;

	p {
		margin-bottom: 0;
	}
}
</style>
