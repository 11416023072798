<template>
	<section class="widget-content">
		<div class="widge-wrap">
			<article class="widget-multiple-question">
				<p>Question text</p>

				<medium-editor
					:options='options'
					class="textinput"
					label="Type question"
					auto-grow
					outlined
					rows="10"
					v-model="question"
				>
				</medium-editor>


				<article
					class="widget-multiple-question__answer"
					v-for="(response, index) in responses"
					:key="response.id"
					:id="response.id"
				>
					<p>Response #{{ index + 1 }}</p>

					<medium-editor
					:options='options'
					class="textinput"
					label="Response text"
					auto-grow
					outlined
					rows="5"
					row-height="2"
					v-model="response.content"
				>
				</medium-editor>

					<v-checkbox
						v-model="response.isTrue"
						label="This is the correct answer"
						color="black"
						:value="true"
					></v-checkbox>


				</article>
				<div class="mt-5">
					<v-btn
						@click="addResponse"
						class="admin-primary-button secondary-contrast-background primary-outline mb-5"
						>Add response</v-btn
					>

				</div>
			</article>
			<article>
				<form-message v-if="message" :type="widgetMessageStatus">{{
					message
				}}</form-message>
			</article>
		</div>


		<div class="widget-bullet-list__button">

<v-btn
	class="w-100 admin-primary-button primary-contrast-background"
	@click="submit"
	>Add question</v-btn
>
</div>

	</section>
</template>
<script>
import MediumEditor from 'vuejs-medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'
import 'highlight.js/styles/ocean.css'

import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import FormMessage from "@/components/FormMessage.vue";

import TurndownService from 'turndown'
const turndownService = new TurndownService()
export default {
	name: "WidgetPopupMultipleQuestion",
	components: { FormMessage, MediumEditor },
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		content() {
			if (this.editedBlock === null) return;
			return this.editedBlock.block.content;
		},
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},
	watch: {
		editedBlockId: function () {
			this.initGetData();
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			question: "",
			responses: [],
			options: {
				placeholder: false,
				toolbar: {
				buttons: ["bold", "italic", "underline"]
				}
      		}
		};
	},
	methods: {
		initGetData() {
			if (this.editedBlock === null) {
				this.addResponse();
				return;
			}
			this.question = this.editedBlock?.block.question;
			this.responses = this.editedBlock?.block.options.map(el => {
				return {
					...el,
					id: el.id,
					isTrue: el.correct,
					content: el.content
				};
			});
		},
		addResponse() {
			this.responses.push({
				id: uuidv4(),
				isTrue: false,
				content: "",
				isNew: true
			});
		},

		async submit() {
			this.$emit("setMessage", {
				val: "",
				type: "widget"
			});
			this.$emit("setWidgetMessageStatus", "");

			if (this.trueResponse === "") {
				this.$emit("setMessage", {
					val: "Please choice correct answer",
					type: "widget"
				});
				this.$emit("setWidgetMessageStatus", "error");
				return;
			}
			if (this.question === "" || this.responses[0].content === "") {
				this.$emit("setMessage", {
					val: "Please fill field",
					type: "widget"
				});
				this.$emit("setWidgetMessageStatus", "error");
				return;
			}

			let question = turndownService.turndown(this.question)

			this.responses.forEach((i,v) => {
				this.responses[v].content = turndownService.turndown(i.content)
			})

			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: Number(this.$route.params.id),
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								title: this.editedBlock.block.title,
								head_level: 2,
								question: question,
								css_class: this.editedBlock.block.css_class,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								title: "Checkbox widget",
								head_level: 2,
								question: question,
								css_class: null,
								sort_order: Number(
									this.activePage.blocks.length
								)
						  },
				blkType: this.currentWidget.blk_type,
				elements: this.responses
			};
			this.$emit("saveBlock", payload);
		}
	},
	mounted() {
		this.initGetData();
		this.addResponse()
		this.addResponse()
		this.addResponse()
	}
};
</script>


<style lang="scss">
.insert-image-container {
	display: none !important;
}
.textinput {
	border: 1px solid #333;
	margin-bottom: 20px;
}

.medium-editor-container {
	padding: 15px 20px;

	p {
		margin-bottom: 0;
	}
}
</style>
