<template>
	<section class="widget-image widget-content">
		<div class="brand-tabs">

		<v-card>
			<v-tabs
				ligth
				background-color="transparent"
				:slider-color="$store.getters.theme.mainAccentColor"
				slider-size="8"
				color="#000"
				v-model="imageType"
				class="brand-tabs-container"
			>

				<v-tab value="fileUpload" @click="clearImage()">File Upload</v-tab>
				<v-tab value="textUpload" @click="clearImage()">Image URL</v-tab>

			</v-tabs>
		</v-card>

</div>
		<div v-if="imageType == 0">
			<div v-if="isEdited">
				<InputImageFile
					v-model="image"
					:id="'widget_image_uploader'"
					:name="'widget_image_uploader'"
				>
					<template #button>Upload Image</template>
				</InputImageFile>
				<v-btn
					class="admin-primary-button"
					v-if="editedBlock !== null"
					@click="setImageEdited(false)"
					>Cancel</v-btn
				>
			</div>
			<div v-else class="d-flex flex-row align-center">
				<h4>{{ imageName }}</h4>

				<v-icon class="ml-4" @click="setImageEdited(true)"
					>mdi-pencil-outline</v-icon
				>
			</div>
		</div>

		<div v-if="imageType == 1" class="mt-5">
			<v-textarea
				v-model="url"
				label="IMAGE URL"
				auto-grow
				outlined
				rows="3"
				row-height="25"
			></v-textarea>

			<div class="imagePreview">
				<span v-if="!url">Preview</span>
				<img v-if="url" :src="url" ref="imgurl">
			</div>
		</div>

		<article>
			<form-message v-if="message" :type="widgetMessageStatus">{{
				message
			}}</form-message>
		</article>



		<div class="widget-bullet-list__button">

<v-btn
	class="w-100 admin-primary-button primary-contrast-background"
	:loading="isLoading"
	@click="submit"
	>Done</v-btn
>
</div>

	</section>
</template>
<script>
import axios from "axios";
import InputImageFile from "@/components/InputImageFile.vue";
import { mapGetters } from "vuex";
import { post } from "@/util/requests/post";
import FormMessage from "@/components/FormMessage.vue";


export default {
	name: "WidgetPopupImage",
	components: {
		InputImageFile,
		FormMessage
	},
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["userId", "isLoading"]),
		imageName() {
			if (this.image?.name) {
				return this.image?.name;
			} else {
				return "";
			}
		},
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			imageType: 1,
			image: null,
			url: "",
			isEdited: true
		};
	},
	watch: {
		editedBlockId: function () {
			this.initGetData();
		}
	},

	methods: {

		clearImage() {
			this.image = null
			this.url = null

			this.$emit("setMessage", {
				val: "",
				type: "widget"
			});
			this.$emit("setWidgetMessageStatus", "");
		},
		initGetData() {
			if (this.editedBlock === null) return;
			this.url = this.editedBlock?.block.caption;
			this.image = this.editedBlock?.block.file;
			this.isEdited = false;
		},
		setImageEdited(val) {
			this.isEdited = val;
		},
		uploadToAPI(img) {

			this.$emit("setMessage", {
				val: "",
				type: "widget"
			});
			this.$emit("setWidgetMessageStatus", "");

			if (img === null) {
				this.$emit("setMessage", {
					val: "Please upload image",
					type: "widget"
				});
				this.$emit("setWidgetMessageStatus", "error");
				return;
			}

			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: Number(this.$route.params.id),
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								title: this.editedBlock.block.title,
								head_level: this.editedBlock.block.head_level,
								caption: this.url ? this.url : "empty",
								css_class: this.editedBlock.block.css_class,
								sort_order: this.editedBlock.block.sort_order,
								header: "eee",
								file: img
						}
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								header: "eee",
								title: "Image",
								head_level: 2,
								caption: this.url ? this.url : "empty",
								css_class: null,
								sort_order: Number(
									this.activePage.blocks.length
								),
								file: img
						},
				blkType: this.currentWidget.blk_type
			};
			this.$emit("saveBlock", payload);

		},
		submit() {
			let img = this.image

			if(this.imageType === 1) {


				// let newImg;

				// const urlImg = this.url
				// const args = {
				// 	method: 'GET',
				// 	mode: 'no-cors',
				// }

				// const req = new Request(urlImg, args)

				// fetch(req).then(function(response) {
				// 	const data = response.blob()
				// 	const filename = urlImg.split("/").pop()
				// 	const metadata = { type: 'image/png' }
				// 	newImg = new File([data], filename, metadata)
				// })

				// setTimeout(() => {
				// 	this.uploadToAPI(newImg)
				// 	console.log(newImg)
				// }, 200)



			// this.toBlob(image)
			// 	.then(res => {
			// 	this.imgurl = {
			// 		filename: res.name,
			// 		size: res.size,
			// 		type: res.type,
			// 		lastModified: res.lastModified
			// 	}
			// 	})

			// 	this.uploadToAPI(this.imgurl)

			} else {
				this.uploadToAPI(img)
			}

		}

		// async uploadImage() {
		// 	const response = await post("/pg_image_blk", {
		// 		user_id: this.userId,
		// 		storage: "image",
		// 		name: this.imageName,
		// 		header: this.imageName,
		// 		ext: this.imageName,
		// 		path: "",
		// 		comment: "Image block"
		// 	});
		// 	return response;
		// }
	},
	created() {
		this.initGetData();
	}
};
</script>

<style lang="scss" scoped>
.imagePreview {
	overflow: hidden;
    height: 50%;
    width: 100%;
    border: 1px solid #9e9e9e;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

	img {
		width: 50%;
		margin: 0 auto;
		display: block;
	}
}

.brand-tabs-container .v-slide-group__content {
	border-bottom: 0 !important;
}
</style>
