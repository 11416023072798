<template>
	<section>
		<article class="widget-paragraph">
			<v-textarea
				v-model="text"
				label="Text"
				auto-grow
				outlined
				rows="10"
				row-height="2"
			></v-textarea>
		</article>
		<article>
			<form-message v-if="message" :type="widgetMessageStatus">{{
				message
			}}</form-message>
		</article>

		<div class="widget-paragraph__button">
			<v-btn
				v-if="editedBlock === null"
				class="admin-primary-button secondary-contrast-background"
				:loading="isLoading"
				@click="submit"
				>Done</v-btn
			>
			<v-btn
				v-else
				class="admin-primary-button secondary-contrast-background"
				:loading="isLoading"
				@click="submit"
				>Save changes</v-btn
			>
		</div>
	</section>
</template>

<script>
import FormMessage from "@/components/FormMessage.vue";
import { mapGetters } from "vuex";
export default {
	name: "WidgetPopupParagraph",
	components: { FormMessage },
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			text: this.editedBlock?.block.content || ""
		};
	},

	methods: {
		async submit() {
			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: Number(this.$route.params.id),
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								title: this.editedBlock.block.title,
								head_level: this.editedBlock.block.head_level,
								content: this.text,
								css_class: this.editedBlock.block.css_class,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								title: "Paragraph",
								head_level: 1,
								content: this.text,
								css_class: null,
								sort_order: Number(
									this.activePage.blocks.length
								)
						  },
				blkType: this.currentWidget.blk_type
			};
			this.$emit("saveBlock", payload);
		}
	}
};
</script>

<style></style>
