<template>
	<section class="widget-content">
		<article class="widget-comparison">
			<p>Question text</p>

			<medium-editor
					:options='options'
					class="textinput"
					v-model="question"
				label="Type question"
				auto-grow
				outlined
				rows="10"
				row-height="2"
				>
				</medium-editor>

			<v-radio-group v-model="trueResponse">
				<v-row>
					<v-col
						cols="6"
						v-for="(node, index) in nodes"
						:key="node.id"
					>
						<article class="widget-comparison__answer">
							<p class="mb-0">Image {{ index + 1 }}</p>
							<InputImageFile
								v-model="node.image"
								:id="String(node.id)"
								:name="String(node.id)"
							>
								<template #button>Upload Image</template>
							</InputImageFile>



							<medium-editor
					:options='options'
					class="textinput"
					v-model="node.response"
								label="Response text"
								auto-grow
								outlined
								rows="5"
								row-height="25"
				>
				</medium-editor>

							<v-radio
								label="This is the correct answer"
								color="black"
								class="mt-4"
								:id="String(node.id)"
								:value="node.id"
								:name="String(node.id)"
							></v-radio>
						</article>
					</v-col>
				</v-row>
			</v-radio-group>
		</article>

		<article>
			<form-message v-if="message" :type="widgetMessageStatus">{{
				message
			}}</form-message>
		</article>

		<div class="widget-bullet-list__button">
			<v-btn
				@click="submit"
				class="w-100 admin-primary-button primary-contrast-background"
				>Add Comparison</v-btn
			>
		</div>
	</section>
</template>
<script>
import MediumEditor from 'vuejs-medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'
import 'highlight.js/styles/ocean.css'

import InputImageFile from "@/components/InputImageFile.vue";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import FormMessage from "@/components/FormMessage.vue";

import TurndownService from 'turndown'
const turndownService = new TurndownService()
export default {
	name: "WidgetPopupComparison",
	components: {
		InputImageFile,
		FormMessage,
		MediumEditor
	},
	props: {
		activePage: {
			type: Object,
			require: true
		},
		editedBlock: {
			type: Object,
			required: false
		},
		currentWidget: {
			type: Object,
			required: true
		},
		message: {
			type: String,
			required: false
		},
		widgetMessageStatus: {
			type: String,
			require: false
		}
	},
	computed: {
		...mapGetters(["isLoading"]),
		editedBlockId() {
			return this.editedBlock?.id;
		}
	},

	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			trueResponse: "",
			question: "",
			nodes: [],
			options: {
				placeholder: false,
				toolbar: {
				buttons: ["bold", "italic", "underline"]
				}
      		}
		};
	},

	watch: {
		editedBlockId: function () {
			this.initGetData();
		},
		trueResponse: function (val) {
			this.nodes.forEach(node => (node.isTrue = false));
			this.nodes.forEach(node => {
				if (node.id === val) {
					node.isTrue = true;
				}
			});
		}
	},

	methods: {
		initGetData() {
			if (this.editedBlock === null) {
				for (let index = 0; index < 2; index++) {
					this.addPair();
				}
			} else {
				this.question = this.editedBlock.block.content;
				this.editedBlock.block.nodes.forEach(el => {
					if (el.correct === 1) {
						this.trueResponse = el.id;
					}
					this.nodes.push({
						id: el.id,
						isTrue: el.correct,
						image: null,
						file_id: el.file_id,
						response: el.content,
						isNew: false
					});
				});
			}
		},
		addPair() {
			this.nodes.push({
				id: uuidv4(),
				isTrue: false,
				image: null,
				response: "",
				isNew: true
			});
		},
		submit() {

			let question = turndownService.turndown(this.question)
			this.nodes.forEach((i,v) => {
				this.nodes[v].response = turndownService.turndown(i.response)
			})

			const payload = {
				content:
					this.editedBlock !== null
						? {
								course_id: this.editedBlock.block.course_id,
								chapter_id: this.editedBlock.block.chapter_id,
								page_id: this.editedBlock.block.page_id,
								content: question,
								css_class: this.editedBlock.css_class,
								sort_order: this.editedBlock.block.sort_order
						  }
						: {
								course_id: Number(this.$route.params.id),
								chapter_id: this.activePage.chapter_id,
								page_id: this.activePage.id,
								content: question,
								css_class: null,
								sort_order: Number(
									this.activePage.blocks.length
								)
						  },
				blkType: this.currentWidget.blk_type,
				elements: this.nodes
			};
			this.$emit("saveBlock", payload);
		}
	},
	mounted() {
		this.initGetData();
	}
};
</script>

<style lang="scss">
.insert-image-container {
	display: none !important;
}
.textinput {
	border: 1px solid #333;
	margin-bottom: 20px;
}

.medium-editor-container {
	padding: 15px 20px;

	p {
		margin-bottom: 0;
	}
}
</style>
